import React from 'react';

const ExportIcon = ({ stroke = '#292D32', width = '24px', height = '24px' }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 11L21.2 2.80005" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M22 6.8V2H17.2" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default ExportIcon;
