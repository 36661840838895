import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'

const ProductItem = () => {
    return (
        <div className='mt-5'>
            <div className='w-full flex '>
                <div className='mr-2 shadow-xl p-2 border border-white rounded-md'>
                    <div className='w-full mb-3 h-44'>
                        <img className=' w-44' src='https://nhacuadau.com/wp-content/uploads/2022/06/hat-hanh-nhan-my-nhap-khau-33.jpg' alt=''/>
                    </div>
                    <div className='text-sm mb-3'>Công ty cổ phần nông sản OKHUB FARM</div>
                    <div className='font-semibold text-sm mb-3'>Hạt hạnh nhân Almond nguyên chất 120g</div>
                    {/* <div>120.000đ/túi</div> */}
                    <div className='font-semibold text-xs text-colorPrimary'>120.000đ/túi</div>
                </div>
                <div className='ml-2 shadow-xl p-2 border border-white rounded-md'>
                    <div className='w-full mb-3 h-44'>
                        <img className='w-44' src='https://organicandhealthfoods.com/FileStorage/Product/Thumbnail/NUT019U_Almonds_Raw200g.jpg' alt=''/>
                    </div>
                    <div className='text-sm mb-3'>Công ty cổ phần nông sản OKHUB FARM</div>
                    <div className='font-semibold text-sm mb-3'>Hạt hạnh nhân Almond nguyên chất 120g</div>
                    {/* <div>120.000đ/túi</div> */}
                    <div className='font-semibold text-xs text-colorPrimary'>120.000đ/túi</div>
                </div>
            </div>
            <div className='w-full flex mt-5'>
                <div className='mr-2 shadow-xl p-2 border border-white rounded-md'>
                    <div className='w-full mb-3 h-44'>
                        <img className=' w-44' src='https://minhphuongfruit.com/upload/sanpham/qsinqvlsx8fostq5mnvf_simg_b5529c_800x800_maxb-1456.jpg' alt=''/>
                    </div>
                    <div className='text-sm mb-3'>Công ty cổ phần nông sản HANUTI FARM</div>
                    <div className='font-semibold text-sm mb-3'>Hạt hạnh nhân Almond nguyên chất 120g</div>
                    {/* <div>120.000đ/túi</div> */}
                    <div className='font-semibold text-xs text-colorPrimary'>120.000đ/túi</div>
                </div>
                <div className='ml-2 shadow-xl p-2 border border-white rounded-md'>
                    <div className='w-full mb-3 h-44'>
                        <img className=' w-44' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn_lV9c4XTXu_xgpBe97Jgx7u-2auKApq6dQ&s' alt=''/>
                    </div>
                    <div className='text-sm mb-3'>Công ty cổ phần nông sản HANUTI FARM</div>
                    <div className='font-semibold text-sm mb-3'>Hạt hạnh nhân Almond nguyên chất 120g</div>
                    {/* <div>120.000đ/túi</div> */}
                    <div className='font-semibold text-xs text-colorPrimary'>120.000đ/túi</div>
                </div>
            </div>
        </div>
    );
};

export default ProductItem;
