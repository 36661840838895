import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'
import SearchIcon from '../assets/icon/SearchIcon';
import NoticeIcon from '../assets/icon/NoticeIcon';
import EditIcon from '../assets/icon/EditIcon';
import ProductIcon from '../assets/icon/ProductIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import EnterpriseIcon from '../assets/icon/EnterpriseIcon';
import NewsIcon from '../assets/icon/NewsIcon';
import LocationIcon from '../assets/icon/LocationIcon';
import ArrowDownIcon from '../assets/icon/ArrowDown';
import CompanyItem from './companyItem';
import ProductItem from './productItem';
import CalendarIcon from '../assets/icon/CalendarIcon';
import StarIcon from '../assets/icon/StarIcon';
import EyeIcon from '../assets/icon/EyeIcon';

const Blog = () => {
    return (
        <div className="mt-7">
            <div className='text-xl font-bold mb-5'>Blog</div>
            <div>
                <img className='border rounded-xl' src='https://cdn.tgdd.vn/2021/11/CookDish/hanh-nhan-la-gi-8-tac-dung-than-ky-cua-hanh-nhan-doi-voi-suc-avt-1200x676.jpg' alt='' />
            </div>
            <div className='mt-4 flex items-center'>
                <div className='mr-3'>
                    <LocationIcon width='20px' height='20px' stroke='#5bbf91' />
                </div>
                <div className='text-sm text-colorPrimary'>Công ty cổ phẩn Hồng Lam</div>
            </div>
            <div className='mt-4 flex items-center' >
                <div className='mr-3'>
                    <CalendarIcon width='20px' height='20px' stroke='#5bbf91' />
                </div>
                <div className='text-sm text-colorPrimary'>20.10.2023</div>
            </div>
            <div className='text-base font-semibold mt-2 mb-2'>
                Thực phẩm sạch đạt chuẩn 2023
            </div>
            <div className='text-xs'>
                Văn hóa ẩm thực là nét văn hóa tự nhiên hình thành trong cuộc sống. Đối với nhiều dân tộc, quốc gia, ẩm thực không chỉ là nét văn hóa về vật chất mà còn là văn hóa về tinh thần. Qua ẩm thực người ta có thể hiểu được nét văn hóa thể hiện phẩm giá con người, trình độ văn hóa của dân tộc với những đạo lý, phép tắc, phong tục trong cách ăn uống…
            </div>
        </div>
    );
};

export default Blog;
