import React, { useState } from 'react';
import LocationIcon from '../assets/icon/LocationIcon';
import { Button, Divider } from 'antd';
import ExportIcon from '../assets/icon/ExportIcon';
import EmailIcon from '../assets/icon/EmailIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import CompanyIcon from '../assets/icon/CompanyIcon';
import GlobalIcon from '../assets/icon/GlobalIcon';
import ContactsIcon from '../assets/icon/ContactIcon';
import EyeIcon from '../assets/icon/EyeIcon';
import CalendarIcon from '../assets/icon/CalendarIcon';
import ArrowUpIcon from '../assets/icon/ArrowUpIcon';
import ArrowDownIcon from '../assets/icon/ArrowDown';
import ThreeDotVerticalIcon from '../assets/icon/ThreeDotVertical';
import ProductItem from './productItem';
import TiktokIcon from '../assets/icon/TiktokIcon';

export const Introduction = () => {
    return <div className=' shadow-lg rounded-lg p-4 m-4'>

        <div className='flex'>
            <ChartIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>Lĩnh vực</div>
                <div className='text-sm text-black/60 font-medium'>
                    Công nghệ phần mềm
                </div>
            </div>
        </div>
        <Divider />
        <div className='flex'>
            <CompanyIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>Tổ chức</div>
                <div className='text-sm text-black/60 font-medium'>
                    Công ty cổ phần nông sản OKHUB FARM
                </div>
            </div>
        </div>
        <Divider />
        <div className='flex'>
            <GlobalIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>okhubfarm.vn</div>
                <div className='text-sm text-black/60 font-medium'>
                    <a href='https://okhubfarm.vn/' target="_blank">https://okhubfarm.vn/</a>

                </div>
            </div>
        </div>
    </div>;
};



export const Details = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleToggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const phoneNumber = '0977680518';
    const maskedPhoneNumber = phoneNumber.slice(0, -4) + '****';
    return <div className=' shadow-lg rounded-lg p-4 m-4'>
        <div className='flex justify-between items-center'>
            <div className='flex'>
                <ContactsIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
                <div className=' ml-2.5'>
                    <div className='text-sm font-semibold'>Số điện thoại</div>
                    <div className='text-sm text-black/60 font-medium'>
                        {isVisible ? phoneNumber : maskedPhoneNumber}
                    </div>
                </div>
            </div>

            <div className=' bg-white h-10 w-10 shadow-lg rounded-full flex justify-center items-center'
                onClick={handleToggleVisibility}
            >
                <EyeIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            </div>
        </div>
        <Divider />
        <div className='flex'>
            <LocationIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>Địa chỉ</div>
                <div className='text-sm text-black/60 font-medium'>
                    123 Hai Bà Trưng, Hà Nội, Việt Nam
                </div>
            </div>
        </div>
        <Divider />
        <div className='flex'>
            <ExportIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>Zalo</div>
                <div className='text-sm text-black/60 font-medium'>
                    <a href='https://zalo.me/0977680518' target='_blank'
                        rel='noopener noreferrer'>https://zalo.me/0977680518</a>

                </div>
            </div>
        </div>
        <Divider />
        <div className='flex'>
            <EmailIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>Email</div>
                <div className='text-sm text-black/60 font-medium'>
                    dongvanhong@gmail.com
                </div>
            </div>
        </div>
        <Divider />
        <div className='flex'>
            <TiktokIcon width={20} height={20} fill='#5bbf91' />
            <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>Tiktok</div>
                <div className='text-sm text-black/60 font-medium'>

                    <a href='https://www.tiktok.com' target='_blank'
                        rel='noopener noreferrer'>https://www.tiktok.com</a>
                </div>
            </div>
        </div>
    </div>;
};



export const Articles = () => {
    const [isExpanded2, setIsExpanded2] = useState(false);

    const fullText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pellentesque, justo in facilisis blandit, elit erat aliquam eros, vel semper orci nibh vel justo. Cras porta lorem at dictum mattis. Nulla viverra, enim sit amet suscipit egestas, eros odio ullamcorper quam, eu commodo justo ante ac velit. Aliquam vel iaculis leo. Proin ornare sapien et fermentum venenatis. Vivamus tempus laoreet justo, ut interdum turpis ultrices fermentum. Aenean volutpat aliquam vehicula. Fusce eu consequat nibh, et ultrices metus. Curabitur dapibus urna vel efficitur pharetra. Duis rhoncus sapien nec lacus congue finibus. Mauris ornare sit amet dolor vitae pellentesque. In nibh lacus, ultricies id urna et, porta dapibus enim. Pellentesque sit amet eros ac dolor bibendum fringilla a sit amet ante.

    Fusce blandit euismod elit eu facilisis. Nam vel tortor felis. Phasellus metus purus, cursus quis vehicula sed, congue at quam. Ut imperdiet massa enim. Duis sed volutpat turpis. Nunc id tempor mauris, id suscipit tortor. Suspendisse sed sem risus. Mauris venenatis sed augue at aliquet.

    Praesent ac ipsum ac mauris porta feugiat. Mauris quis arcu vel mi commodo sagittis ut ac neque. Nullam dui arcu, volutpat nec cursus eu, pretium eu tortor. Fusce nunc eros, lobortis id convallis et, convallis vitae tortor. Quisque luctus posuere turpis sed ultrices. Donec accumsan vulputate leo, nec sodales augue placerat a. Cras finibus ante et est varius, ut fermentum nunc eleifend. Etiam id dolor eu leo interdum fringilla. In quis nunc non justo ullamcorper sodales eget sed turpis. Mauris vitae euismod sem. Morbi quis suscipit ligula. Curabitur lacinia dui urna, quis tincidunt diam vulputate eu. Praesent feugiat ut enim nec ullamcorper.`;

    const midPoint = Math.floor(fullText.length / 2);
    const initialText = fullText.slice(0, midPoint);
    const displayedText2 = isExpanded2 ? fullText : initialText;
    return <div className='p-4'>
        <div className='flex justify-between items-center'>
            <div className='flex items-center'>
                <div className=' rounded-full'><img src='https://i.pinimg.com/564x/ad/e0/df/ade0df428fb2ce2bf37bb97e2e9a1f11.jpg' className='w-12 h-12 rounded-full' alt='' /></div>
                <div>
                    <div className='py-1 px-2.5 text-base font-semibold'>Công ty cổ phần Hồng Lam</div>
                    <div className='flex'>
                        <div className=' bg-white rounded-lg flex justify-between py-1.5 px-2.5 items-center'>
                            <CalendarIcon width='16px' height='16px' />
                            <div className='text-black/60 font-medium text-xs ml-2'>
                                20.10.2023
                            </div>
                        </div>
                        <div className=' bg-white shadow-lg rounded-lg flex justify-between py-1.5 px-2.5 items-center'>
                            <GlobalIcon width='16px' height='16px' stroke="#5bbf91" />
                            <div className='text-colorPrimary font-medium text-xs ml-2'>
                                Được tài trợ
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className=' bg-white h-10 w-10 shadow-lg rounded-full flex justify-center items-center'
            >
                <ThreeDotVerticalIcon width={16} height={16} stroke='#5bbf91' fill='#5bbf91' />
            </div>
        </div>
        <div className='relative shadow-lg my-4 -mx-4'>
            <img src='https://i.pinimg.com/564x/ad/e0/df/ade0df428fb2ce2bf37bb97e2e9a1f11.jpg' className='' alt='' />

        </div>
        <div className='mt-2 shadow-lg -mx-4 px-4 rounded-b-lg'>
            <div className='relative'>
                <div className={`text-xs ${isExpanded2 ? '' : 'blurred'}`}>
                    {displayedText2}
                </div>
                {!isExpanded2 && (
                    <div className='absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-white to-transparent'></div>
                )}
            </div>

            <div className='flex items-center justify-center cursor-pointer py-2'
                onClick={() => setIsExpanded2(!isExpanded2)}
            >
                <div className='text-colorPrimary font-semibold uppercase text-sm mr-2'>
                    {isExpanded2 ? 'Thu gọn' : 'Đọc thêm'}
                </div>
                {isExpanded2 ? <ArrowUpIcon fill='#5bbf91' width={12} height={12} /> : <ArrowDownIcon fill='#5bbf91' width={16} height={16} />}

            </div>
            {isExpanded2 ? <div className=' w-24 border-t-2 border-colorPrimary py-2 mx-auto'></div> : <div className=' w-26 border-t-2 border-colorPrimary py-2 mx-auto'></div>}

        </div>
    </div>;
};



export const Products = () => {
    return <div className='px-4'><ProductItem /></div>;
};

export const Legal = () => {
    return <div className='p-4'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</div>;
};

