/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Button, Input, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../assets/scss/global.scss'

const Login = () => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    if (values.username === 'admin' && values.password === '123') {
      notification.success({
        message: 'Đăng nhập thành công!',
        description: 'Bạn đã đăng nhập thành công',
      });

      setTimeout(() => {
        navigate('/home');
      }, 1500);
    } else {
      notification.error({
        message: 'Đăng nhập thất bại',
        description: 'Tên đăng nhập hoặc mật khẩu không đúng!',
      });
    }
  };

  return (
    <div className="p-8 max-w-md mx-auto">
      <div>
        <img src='https://upload.wikimedia.org/wikipedia/commons/8/83/Green_Party_of_Aotearoa_New_Zealand_logo.jpg' />
      </div>
      <h2 className="text-2xl mb-4 mt-8 font-serif text-center">Đăng nhập</h2>
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item
          name="username"
          label="Tên đăng nhập"
          rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập của bạn!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu của bạn!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size={"large"}
            className="w-full"
            style={{ backgroundColor: '#5bbf91', borderColor: '#5bbf91' }}
          >
            Đăng nhập
          </Button>
        </Form.Item>
      </Form>
      <div className="text-center mt-4">
        <span>Bạn chưa có tài khoản? </span>
        <a href="/register" className="text-colorPrimary underline">Đăng ký</a>
      </div>
    </div>
  );
};

export default Login;
