import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import BusinessCard from './pages/BusinessCard';
import Contact from './pages/Contact';
import Account from './pages/Account';
import NavigationBar from './components/navigationBar';
import BusinessDetail from './pages/BusinessDetail';

function App() {
  const location = useLocation();
  const showNavigationBar = !['/login', '/register'].includes(location.pathname);

  return (
    <div className="App flex flex-col min-h-screen">
      <main className="flex-1">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="/business-card" element={<BusinessCard />} />
          <Route path="/business-detail" element={<BusinessDetail />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/account" element={<Account />} />
        </Routes>
      </main>
      {showNavigationBar && <NavigationBar />}
    </div>
  );
}

export default App;
