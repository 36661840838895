import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'
import SearchIcon from '../assets/icon/SearchIcon';
import NoticeIcon from '../assets/icon/NoticeIcon';
import EditIcon from '../assets/icon/EditIcon';
import ProductIcon from '../assets/icon/ProductIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import EnterpriseIcon from '../assets/icon/EnterpriseIcon';
import NewsIcon from '../assets/icon/NewsIcon';
import LocationIcon from '../assets/icon/LocationIcon';
import ArrowDownIcon from '../assets/icon/ArrowDown';
import CompanyItem from './companyItem';
import ProductItem from './productItem';
import CalendarIcon from '../assets/icon/CalendarIcon';
import StarIcon from '../assets/icon/StarIcon';
import EyeIcon from '../assets/icon/EyeIcon';
import GlobalIcon from '../assets/icon/GlobalIcon';

const Post = () => {
    return (
        <div className="mt-7 ">
            <div className='text-xl font-bold mb-5'>
                Bài viết
            </div>
            <div>
                <div className='flex items-center'>
                    <div className='w-14 h-full'>
                        <img className='w-14 h-14 rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjshxZ0WJagT91mJ9T13vfoz5MrP7PaRCEw&s' alt='' />
                    </div>
                    <div className=''>
                        <div className='py-1 px-2.5 text-base font-semibold'>Công ty cổ phẩn Nestle</div>
                        <div className='flex'>
                            <div className=' bg-white rounded-lg flex justify-between py-1.5 px-2.5 items-center'>
                                <CalendarIcon width='16px' height='16px' />
                                <div className='text-black/60 font-medium text-xs ml-2'>
                                    20.10.2023
                                </div>
                            </div>
                            <div className=' bg-white shadow-lg rounded-lg flex justify-between py-1.5 px-2.5 items-center'>
                                <GlobalIcon width='16px' height='16px' stroke="#5bbf91" />
                                <div className='text-colorPrimary font-medium text-xs ml-2'>
                                    Được tài trợ
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <div className='mt-5'>
                    <iframe width={'100%'} height={'200px'} className='rounded-lg' src="https://www.youtube.com/embed/vPRtSPGabBQ?si=CTzE9T5GhOTeQbds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='mt-4 text-xs'>
                    MILO® cam kết phát triển bền vững bằng việc sử dụng các nguyên liệu tự nhiên, hợp tác cùng các đơn vị cung ứng uy tín và không ngừng kiểm soát quy trình sản xuất với tiêu chuẩn nghiêm ngặt để đảm bảo tối ưu chất dinh dưỡng cùng hương vị trong mỗi sản phẩm của mình đến tay người tiêu dùng.
                </div>
            </div>

            <div className='mt-5'>
                <div className='flex items-center'>
                    <div className='w-14 h-full'>
                        <img className='w-14 h-14 border shadow-lg rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLADVFopInpqhApYqw7bbgN0yupoEGJEWmA&s' alt='' />
                    </div>
                    <div className=''>
                        <div className='py-1 px-2.5 text-base font-semibold '>Công ty cổ phẩn Hồng Lam</div>
                        <div className='flex'>
                            <div className=' bg-white rounded-lg flex justify-between py-1.5 px-2.5 items-center'>
                                <CalendarIcon width='16px' height='16px' />
                                <div className='text-black/60 font-medium text-xs ml-2'>
                                    20.10.2023
                                </div>
                            </div>
                            <div className=' bg-white shadow-lg rounded-lg flex justify-between py-1.5 px-2.5 items-center'>
                                <GlobalIcon width='16px' height='16px' stroke="#5bbf91" />
                                <div className='text-colorPrimary font-medium text-xs ml-2'>
                                    Được tài trợ
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    <iframe width={'100%'} height={'200px'} className='rounded-lg' src="https://www.youtube.com/embed/A8saX4uuEek?si=CTzE9T5GhOTeQbds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='mt-4 text-xs'>
                    Sắc Thu mang nét đẹp dịu dàng của thời điểm giao mùa, dưới ánh trăng đoàn viên, những cành hồng e ấp trĩu quả, tượng trưng cho ước nguyện về cuộc sống an nhiên, hạnh phúc trên khắp thế gian.

                    Bộ quà Sắc Thu hội tụ những sản vật của tạo hoá, qua đôi bàn tay khéo léo của những nghệ nhân Hồng Lam để trở thành Tinh hoa quà Việt đi khắp muôn nơi.
                </div>
            </div>
        </div>
    );
};

export default Post;
