import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'
import SearchIcon from '../assets/icon/SearchIcon';
import NoticeIcon from '../assets/icon/NoticeIcon';
import EditIcon from '../assets/icon/EditIcon';
import ProductIcon from '../assets/icon/ProductIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import EnterpriseIcon from '../assets/icon/EnterpriseIcon';
import NewsIcon from '../assets/icon/NewsIcon';
import LocationIcon from '../assets/icon/LocationIcon';
import ArrowDownIcon from '../assets/icon/ArrowDown';
import CompanyItem from './companyItem';
import ProductItem from './productItem';

const Product = () => {
    return (
        <div className="mt-7 ">
            <div className='text-xl font-bold mb-5'>
                Sản phẩm
            </div>
            <div className='flex w-full overflow-x-auto'>
                <div className='min-w-24 mr-3'>
                    <button className='w-full border border-colorPrimary rounded-lg bg-colorPrimary h-10 text-white font-semibold text-xs'>
                        NÔNG SẢN
                    </button>
                </div>
                <div className='min-w-24 mr-3'>
                    <button className='w-full border border-colorSecondary rounded-lg bg-white  h-10 text-colorSecondary font-semibold text-xs'>
                        GIÁO DỤC
                    </button>
                </div>
                <div className='min-w-24 mr-3'>
                    <button className='w-full border border-colorSecondary rounded-lg bg-white  h-10 text-colorSecondary font-semibold text-xs'>
                        Y TẾ
                    </button>
                </div>
                <div className='min-w-24 mr-3'>
                    <button className='w-full border border-colorSecondary rounded-lg bg-white h-10 text-colorSecondary font-semibold text-xs'>
                        THỦY SẢN
                    </button>
                </div>
            </div>
            <div>
                <ProductItem />
            </div>
        </div>
    );
};

export default Product;
