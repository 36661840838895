import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'
import SearchIcon from '../assets/icon/SearchIcon';
import NoticeIcon from '../assets/icon/NoticeIcon';
import EditIcon from '../assets/icon/EditIcon';
import ProductIcon from '../assets/icon/ProductIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import EnterpriseIcon from '../assets/icon/EnterpriseIcon';
import NewsIcon from '../assets/icon/NewsIcon';
import '../assets/scss/global.scss'
import CompanyIcon from '../assets/icon/CompanyIcon';
import NoteIcon from '../assets/icon/NoteIcon';

const HeaderContentHome = () => {
    return (
        <div className="">
            <div className='border rounded-lg border-colorPrimary'>
                <img src='https://www.tailorbrands.com/wp-content/uploads/2020/08/How-to-Combine-Green.jpg' alt=''/>
            </div>
            <div className='mt-7'>
                <div className='mb-7 flex flex-row justify-evenly'>
                    <div className='flex flex-col justify-center items-center w-1/4'>
                        <div className=' w-10 h-10 bg-colorPrimary border-colorPrimary p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ProductIcon />
                        </div>
                        <div className='font-semibold text-sm'>Sản phẩm</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-colorPrimary border-colorPrimary p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ChartIcon stroke='white' width={20} height={20}/>
                        </div>
                        <div className='font-semibold text-sm'>Lĩnh vực</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-colorPrimary border-colorPrimary p-2 border rounded-full flex justify-center items-center mb-2'>
                            <CompanyIcon fill='white' width={20} height={20}/>
                        </div>
                        <div className='font-semibold text-sm'>Doanh nghiệp</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-colorPrimary border-colorPrimary p-2 border rounded-full flex justify-center items-center mb-2'>
                            <NewsIcon />
                        </div>
                        <div className='font-semibold text-sm'>Tin tức</div>
                    </div>
                </div>
                <div className='flex justify-center items-center'>
                    <div className='text-colorPrimary mr-3 text-base font-semibold'>Đăng ký danh thiếp điện tử miễn phí</div>
                    <div>
                        <NoteIcon width={20} height={20} stroke='#5bbf91'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderContentHome;
