import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'
import SearchIcon from '../assets/icon/SearchIcon';
import NoticeIcon from '../assets/icon/NoticeIcon';
import EditIcon from '../assets/icon/EditIcon';
import ProductIcon from '../assets/icon/ProductIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import EnterpriseIcon from '../assets/icon/EnterpriseIcon';
import NewsIcon from '../assets/icon/NewsIcon';
import LocationIcon from '../assets/icon/LocationIcon';
import ArrowDownIcon from '../assets/icon/ArrowDown';
import CompanyItem from './companyItem';

const Company = () => {
    return (
        <div className="mt-7 bg">
            <div className='text-xl font-semibold'>Doanh Nghiệp</div>
            <div className='flex mt-5'>
                <div className='flex flex-row justify-between items-center py-1.5 px-2 border border-colorPrimary rounded-md mr-5'>
                    <div>
                        <LocationIcon width={"20px"} height={"20px"} stroke='#5bbf91'/>
                    </div>
                    <div className='text-sm font-semibold mr-5 ml-2 text-colorPrimary'>
                        Hà Nội
                    </div>
                    <div>
                        <ArrowDownIcon width={"16px"} height={"16px"} fill='#5bbf91'/>
                    </div>
                </div>
                <div className='flex flex-row justify-between items-center py-1.5 px-2 border border-colorPrimary rounded-md'>
                    <div>
                        <ChartIcon width={"20px"} height={"20px"} stroke='#5bbf91'/>
                    </div>
                    <div className='text-sm font-semibold mr-5 ml-2 text-colorPrimary'>
                        Lĩnh vực
                    </div>
                    <div>
                        <ArrowDownIcon width={"16px"} height={"16px"} fill='#5bbf91'/>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <CompanyItem />
            </div>
        </div>
    );
};

export default Company;
