import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import ShareIcon from '../assets/icon/ShareIcon';
import ArrowLeftIcon from '../assets/icon/ArrowLeftIcon';
import { Introduction, Details, Articles, Products, Legal } from '../components/content-tabbar';
import TabBar from '../components/tabbar';
import { useNavigate } from 'react-router-dom';

const BusinessDetail = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleToggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const phoneNumber = '0964055483';
    const maskedPhoneNumber = phoneNumber.slice(0, -4) + '****';

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);

    const fullText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pellentesque, justo in facilisis blandit, elit erat aliquam eros, vel semper orci nibh vel justo. Cras porta lorem at dictum mattis. Nulla viverra, enim sit amet suscipit egestas, eros odio ullamcorper quam, eu commodo justo ante ac velit. Aliquam vel iaculis leo. Proin ornare sapien et fermentum venenatis. Vivamus tempus laoreet justo, ut interdum turpis ultrices fermentum. Aenean volutpat aliquam vehicula. Fusce eu consequat nibh, et ultrices metus. Curabitur dapibus urna vel efficitur pharetra. Duis rhoncus sapien nec lacus congue finibus. Mauris ornare sit amet dolor vitae pellentesque. In nibh lacus, ultricies id urna et, porta dapibus enim. Pellentesque sit amet eros ac dolor bibendum fringilla a sit amet ante.

            Fusce blandit euismod elit eu facilisis. Nam vel tortor felis. Phasellus metus purus, cursus quis vehicula sed, congue at quam. Ut imperdiet massa enim. Duis sed volutpat turpis. Nunc id tempor mauris, id suscipit tortor. Suspendisse sed sem risus. Mauris venenatis sed augue at aliquet.

            Praesent ac ipsum ac mauris porta feugiat. Mauris quis arcu vel mi commodo sagittis ut ac neque. Nullam dui arcu, volutpat nec cursus eu, pretium eu tortor. Fusce nunc eros, lobortis id convallis et, convallis vitae tortor. Quisque luctus posuere turpis sed ultrices. Donec accumsan vulputate leo, nec sodales augue placerat a. Cras finibus ante et est varius, ut fermentum nunc eleifend. Etiam id dolor eu leo interdum fringilla. In quis nunc non justo ullamcorper sodales eget sed turpis. Mauris vitae euismod sem. Morbi quis suscipit ligula. Curabitur lacinia dui urna, quis tincidunt diam vulputate eu. Praesent feugiat ut enim nec ullamcorper.`;

    const midPoint = Math.floor(fullText.length / 2);
    const initialText = fullText.slice(0, midPoint);
    const displayedText = isExpanded ? fullText : initialText;
    const displayedText2 = isExpanded2 ? fullText : initialText;


    const [activeTab, setActiveTab] = useState('introduction');

    const renderContent = () => {
        switch (activeTab) {
            case 'introduction':
                return <Introduction />;
            case 'details':
                return <Details />;
            case 'articles':
                return <Articles />;
            case 'products':
                return <Products />;
            case 'legal':
                return <Legal />;
            default:
                return <Introduction />;
        }
    };

    const navigate = useNavigate();

    return (
        <div className="max-w-md mx-auto rounded-lg h-full pb-24 relative">
            <div className='bg-outter bg-cover bg-center h-48'>
            </div>
            <div className='absolute top-6 w-full px-6'>
                <div className='flex justify-between'>
                    <div className=' bg-white h-10 w-10 shadow-lg rounded-full flex justify-center items-center'
                        onClick={() => navigate('/business-card')}
                    >
                        <ArrowLeftIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
                    </div>
                    <div className=' bg-white h-10 w-10 shadow-lg rounded-full flex justify-center items-center'
                    >
                        <ShareIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
                    </div>
                </div>
            </div>
            <div className=' flex justify-between'>
                <div className=' w-28 h-28 border-4 border-white rounded-full ml-5 -mt-12 relative'>
                    <div

                        className="w-26 h-26 rounded-full bg-avt bg-cover"
                    ></div>
                    <div className='bg-colorSecondary text-sm flex justify-center items-center font-semibold w-8 h-8 rounded-full text-center absolute text-white -top-2 -right-1'>
                        #1
                    </div>
                </div>
                <div className='flex mt-5 mr-10 items-center'>
                    <div className='text-sm font-semibold text-center'>
                        Người quan tâm
                        <div className='text-colorPrimary'>236</div>
                    </div>
                    <div className='w-0.5 h-4 mx-5 bg-black/40'></div>
                    <div className='text-sm font-semibold text-center'>
                        Sản phẩm
                        <div className='text-colorPrimary'>56</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">

                <h2 className="text-xl font-semibold mx-4 mt-2 uppercase">okhub.farm</h2>
                <p className="text-sm text-black/60 font-medium mx-4">Công ty cung cấp sản phẩm nông sản sạch, hàng đầu và uy tín nhất tại Việt Nam</p>
            </div>
            <div className='mb-2 px-4 flex'>
                <Button
                    type="primary"
                    className=" flex items-center bg-colorPrimary border-colorPrimary hover:bg-colorPrimary "
                    size="medium"
                >
                    <div className='text-sm font-medium uppercase text-white'>
                        Nông sản
                    </div>

                </Button>
            </div>
            <div className='my-4'>
                <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className='mt-4'>
                    {renderContent()}
                </div>
            </div>


        </div>
    );
};

export default BusinessDetail;
