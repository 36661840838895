import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import PlusIcon from '../assets/icon/PlusIcon';
import QRCodeScanIcon from '../assets/icon/QRCodeScanIcon';
import StarIcon from '../assets/icon/StarIcon';
import ContactsIcon from '../assets/icon/ContactIcon';
import ShareIcon from '../assets/icon/ShareIcon';
import NoteIcon from '../assets/icon/NoteIcon';
import EyeIcon from '../assets/icon/EyeIcon';
import LocationIcon from '../assets/icon/LocationIcon';
import ExportIcon from '../assets/icon/ExportIcon';
import EmailIcon from '../assets/icon/EmailIcon';
import CalendarIcon from '../assets/icon/CalendarIcon';
import ArrowDownIcon from '../assets/icon/ArrowDown';
import ArrowUpIcon from '../assets/icon/ArrowUpIcon';
import { useNavigate } from 'react-router-dom';
import TiktokIcon from '../assets/icon/TiktokIcon';

const BusinessCard = () => {

  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const phoneNumber = '0977680518';
  const maskedPhoneNumber = phoneNumber.slice(0, -4) + '****';

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);

  const fullText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pellentesque, justo in facilisis blandit, elit erat aliquam eros, vel semper orci nibh vel justo. Cras porta lorem at dictum mattis. Nulla viverra, enim sit amet suscipit egestas, eros odio ullamcorper quam, eu commodo justo ante ac velit. Aliquam vel iaculis leo. Proin ornare sapien et fermentum venenatis. Vivamus tempus laoreet justo, ut interdum turpis ultrices fermentum. Aenean volutpat aliquam vehicula. Fusce eu consequat nibh, et ultrices metus. Curabitur dapibus urna vel efficitur pharetra. Duis rhoncus sapien nec lacus congue finibus. Mauris ornare sit amet dolor vitae pellentesque. In nibh lacus, ultricies id urna et, porta dapibus enim. Pellentesque sit amet eros ac dolor bibendum fringilla a sit amet ante.

  Fusce blandit euismod elit eu facilisis. Nam vel tortor felis. Phasellus metus purus, cursus quis vehicula sed, congue at quam. Ut imperdiet massa enim. Duis sed volutpat turpis. Nunc id tempor mauris, id suscipit tortor. Suspendisse sed sem risus. Mauris venenatis sed augue at aliquet.

  Praesent ac ipsum ac mauris porta feugiat. Mauris quis arcu vel mi commodo sagittis ut ac neque. Nullam dui arcu, volutpat nec cursus eu, pretium eu tortor. Fusce nunc eros, lobortis id convallis et, convallis vitae tortor. Quisque luctus posuere turpis sed ultrices. Donec accumsan vulputate leo, nec sodales augue placerat a. Cras finibus ante et est varius, ut fermentum nunc eleifend. Etiam id dolor eu leo interdum fringilla. In quis nunc non justo ullamcorper sodales eget sed turpis. Mauris vitae euismod sem. Morbi quis suscipit ligula. Curabitur lacinia dui urna, quis tincidunt diam vulputate eu. Praesent feugiat ut enim nec ullamcorper.`;

  const midPoint = Math.floor(fullText.length / 2);
  const initialText = fullText.slice(0, midPoint);
  const displayedText = isExpanded ? fullText : initialText;
  const displayedText2 = isExpanded2 ? fullText : initialText;
  const navigate = useNavigate();
  return (
    <div className="max-w-md mx-auto shadow-lg rounded-lg h-auto pb-24">
      <div className='bg-outter bg-cover bg-center h-44'>
      </div>
      <div className=' flex justify-center -mt-12 '>
        <div className=' w-28 h-28 border-4 border-white rounded-full'>
          <div
            // src="https://i.pinimg.com/564x/ad/e0/df/ade0df428fb2ce2bf37bb97e2e9a1f11.jpg"
            // alt="Profile"
            className="w-26 h-26 rounded-full  bg-avt bg-cover"
          ></div>
        </div>

      </div>
      <div className="flex flex-col items-center text-center">

        <h2 className="text-2xl font-semibold mt-2">Đồng Văn Hồng</h2>
        <p className="text-sm text-black/60 font-medium mx-5">Chuyên gia tư vấn thiết kế website, giáo dục, nông sản hàng đầu Việt Nam</p>
      </div>
      <div className='my-4 px-4 flex'>

        <Button
          type="default"
          className=" w-1/2 px-2 mr-1 border border-colorPrimary text-colorPrimary uppercase text-xs font-medium"
          size="large"
          onClick={() => navigate('/business-detail')}
        >
          Doanh nghiệp của tôi
        </Button>
        <Button
          type="primary"
          className="w-1/2 ml-1 flex items-center bg-colorPrimary border-colorPrimary hover:bg-colorPrimary "
          size="large"
        >
          <div className='text-xs font-medium uppercase text-white'>
            Lưu danh thiếp
          </div>
          <PlusIcon stroke='#FFFFFF' width={20} height={20} />

        </Button>
      </div>
      <div className=' mx-4 px-6 py-4 shadow-lg rounded-lg'>
        <div className='flex justify-between'>
          <div className='flex flex-col justify-center items-center'>
            <div className='rounded-full bg-white shadow-md w-12 h-12 flex justify-center items-center'>
              <QRCodeScanIcon width={24} height={24} stroke='#5bbf91' fill='#5bbf91' />

            </div>
            <div className=' text-xs font-semibold mt-2.5 uppercase'>
              Mã QR
            </div>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <div className='rounded-full bg-white shadow-md w-12 h-12 flex justify-center items-center'>
              <StarIcon width={24} height={24} stroke='#5bbf91' fill='#5bbf91' />

            </div>
            <div className=' text-xs font-semibold mt-2.5 uppercase'>
              Quan tâm
            </div>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <div className='rounded-full bg-white shadow-md w-12 h-12 flex justify-center items-center'>
              <ContactsIcon width={24} height={24} stroke='#5bbf91' fill='#5bbf91' />

            </div>
            <div className=' text-xs font-semibold mt-2.5 uppercase'>
              liên hệ
            </div>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <div className='rounded-full bg-white shadow-md w-12 h-12 flex justify-center items-center'>
              <ShareIcon width={24} height={24} stroke='#5bbf91' fill='#5bbf91' />

            </div>
            <div className=' text-xs font-semibold mt-2.5 uppercase'>
              Chia sẻ
            </div>
          </div>
        </div>
        <div className='rounded-lg bg-white shadow-md flex items-center justify-center py-2 px-3 mt-3.5'>
          <div className='text-colorPrimary text-xs mr-1 font-semibold'>
            Đăng ký danh thiếp điện tử miễn phí
          </div>
          <NoteIcon width={24} height={24} stroke='#5bbf91' />
        </div>
      </div>
      <div className='mx-4 my-8'>
        <div className=' text-2xl font-bold'>
          Thông tin
        </div>
        <div className=' shadow-lg rounded-lg p-4 my-4'>
          <div className='flex justify-between items-center'>
            <div className='flex'>
              <ContactsIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
              <div className=' ml-2.5'>
                <div className='text-sm font-semibold'>Số điện thoại</div>
                <div className='text-sm text-black/60 font-medium'>
                  {isVisible ? phoneNumber : maskedPhoneNumber}
                </div>
              </div>
            </div>

            <div className=' bg-white h-10 w-10 shadow-lg rounded-full flex justify-center items-center'
              onClick={handleToggleVisibility}
            >
              <EyeIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            </div>
          </div>
          <Divider />
          <div className='flex'>
            <LocationIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
              <div className='text-sm font-semibold'>Địa chỉ</div>
              <div className='text-sm text-black/60 font-medium'>
                123 Hai Bà Trưng, Hà Nội, Việt Nam
              </div>
            </div>
          </div>
          <Divider />
          <div className='flex'>
            <ExportIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
              <div className='text-sm font-semibold'>Zalo</div>
              <div className='text-sm text-black/60 font-medium'>
                <a href='https://zalo.me/0977680518' target='_blank'
                  rel='noopener noreferrer'>https://zalo.me/0977680518</a>

              </div>
            </div>
          </div>
          <Divider />
          <div className='flex'>
            <EmailIcon width={20} height={20} stroke='#5bbf91' fill='#5bbf91' />
            <div className=' ml-2.5'>
              <div className='text-sm font-semibold'>Email</div>
              <div className='text-sm text-black/60 font-medium'>
                dongvanhong@gmail.com
              </div>
            </div>
          </div>
          <Divider />
          <div className='flex'>
            <TiktokIcon width={20} height={20} fill='#5bbf91' />
            <div className=' ml-2.5'>
              <div className='text-sm font-semibold'>Tiktok</div>
              <div className='text-sm text-black/60 font-medium'>

                <a href='https://www.tiktok.com' target='_blank'
                  rel='noopener noreferrer'>https://www.tiktok.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mx-4 my-8'>
        <div className='text-2xl font-bold'>Mô tả</div>
        <div className='relative shadow-lg rounded-lg p-4 my-4'>
          <div className={`text-content text-xs ${isExpanded ? '' : 'blurred'}`}>
            {displayedText}
          </div>
          {!isExpanded && (
            <div className='absolute bottom-0 left-0 w-full h-10 bg-gradient-to-t from-white to-transparent'></div>
          )}
        </div>
        <div className='flex justify-center'>
          <Button
            type="default"
            className="border border-colorPrimary text-colorPrimary text-sm font-medium"
            size="large"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Rút gọn' : 'Xem thêm'}
          </Button>
        </div>

      </div>
      <div className='mx-4 my-8'>
        <div className='flex justify-between items-center'>
          <div className='text-2xl font-bold'>Youtube</div>
          <div className=' bg-colorPrimary h-10 w-10 shadow-lg rounded-full flex justify-center items-center'

          >
            <ExportIcon width={20} height={20} stroke='#FFFFFF' fill='#5bbf91' />
          </div>
        </div>

        <div className='relative shadow-lg rounded-lg my-4'>
          <iframe width={'100%'} height={'200px'} className='rounded-lg' src="https://www.youtube.com/embed/kqLOSsyhK8c?si=ZzcQ2Nph3z0RJf_Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>

      </div>
      <div className='mx-4 my-8'>
        <div className='text-2xl font-bold'>Blog</div>


        <div className='relative shadow-lg rounded-lg my-4'>
          <div className='rounded-lg bg-outter bg-cover h-48' ></div>
          <div className=' bg-white rounded-lg flex justify-between py-1.5 px-2.5 items-center absolute bottom-2 right-2'>
            <CalendarIcon width='16px' height='16px' />
            <div className='text-black/60 font-medium text-xs ml-2'>
              20.10.2023
            </div>
          </div>
        </div>

        <div className='text-xl font-bold'>Thực phẩm sạch đạt chuẩn 2023</div>
        <div className='mt-2 shadow-lg -mx-4 px-4 rounded-b-lg'>
          <div className='relative'>
            <div className={`text-xs ${isExpanded2 ? '' : 'blurred'}`}>
              {displayedText2}
            </div>
            {!isExpanded2 && (
              <div className='absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-white to-transparent'></div>
            )}
          </div>

          <div className='flex items-center justify-center cursor-pointer py-2'
            onClick={() => setIsExpanded2(!isExpanded2)}
          >
            <div className='text-colorPrimary font-semibold uppercase text-sm mr-2'>
              {isExpanded2 ? 'Thu gọn' : 'Đọc thêm'}
            </div>
            {isExpanded2 ? <ArrowUpIcon fill='#5bbf91' width={12} height={12} /> : <ArrowDownIcon fill='#5bbf91' width={16} height={16} />}

          </div>
          {isExpanded2 ? <div className=' w-24 border-t-2 border-colorPrimary py-2 mx-auto'></div> : <div className=' w-26 border-t-2 border-colorPrimary py-2 mx-auto'></div>}
        </div>

      </div>
      <div className='mx-4 my-8'>
        <div className='text-2xl font-bold uppercase'>Phần thưởng</div>

        <div className='flex'>
          <div className='relative my-4 w-1/2 flex flex-col items-center justify-center mr-2'>
            <div>
              <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUU7VRFn1ugTACBpUJEnhkMJDmM4LJ-T7GEQ&s' className='rounded-lg shadow-lg' alt='' />
            </div>
            <div className=' uppercase text-sm font-semibold mt-2 text-center'>
              Giải thưởng doanh nghiệp xanh 2023
            </div>
          </div>
          <div className='relative my-4 w-1/2 flex flex-col items-center justify-center ml-2'>
            <div>
              <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUU7VRFn1ugTACBpUJEnhkMJDmM4LJ-T7GEQ&s' className='rounded-lg shadow-lg' alt='' />
            </div>
            <div className=' uppercase text-sm font-semibold mt-2 text-center'>
              Giải thưởng doanh nghiệp xanh 2023
            </div>
          </div>
        </div>
        <div className='flex'>
          <div className='relative my-4 w-1/2 flex flex-col items-center justify-center mr-2'>
            <div>
              <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUU7VRFn1ugTACBpUJEnhkMJDmM4LJ-T7GEQ&s' className='rounded-lg shadow-lg' alt='' />
            </div>
            <div className=' uppercase text-sm font-semibold mt-2 text-center'>
              Giải thưởng doanh nghiệp xanh 2023
            </div>
          </div>
          <div className='relative my-4 w-1/2 flex flex-col items-center justify-center ml-2'>
            <div>
              <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUU7VRFn1ugTACBpUJEnhkMJDmM4LJ-T7GEQ&s' className='rounded-lg shadow-lg' alt='' />
            </div>
            <div className=' uppercase text-sm font-semibold mt-2 text-center'>
              Giải thưởng doanh nghiệp xanh 2023
            </div>
          </div>
        </div>

      </div>


    </div>
  );
};

export default BusinessCard;
