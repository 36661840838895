import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'
import SearchIcon from '../assets/icon/SearchIcon';
import NoticeIcon from '../assets/icon/NoticeIcon';
import '../assets/scss/global.scss'

const HeaderHome = () => {
    return (
        <div className="flex flex-row justify-between p-4">
            <div className='logo h-14 w-48'>
                <img src='https://upload.wikimedia.org/wikipedia/commons/8/83/Green_Party_of_Aotearoa_New_Zealand_logo.jpg' alt=''/>
            </div>
            <div className='flex flex-row justify-center items-center'>
                <div className='mr-2'>
                    <SearchIcon />
                </div>
                <div >
                    <NoticeIcon />
                </div>
            </div>
        </div>
    );
};

export default HeaderHome;
