import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '../assets/icon/HomeIcon';
import ContactsIcon from '../assets/icon/ContactIcon';
import QRCodeIcon from '../assets/icon/QRCodeIcon';
import BusinessCardIcon from '../assets/icon/BusinessCardIcon';
import AccountIcon from '../assets/icon/AccountIcon';
import '../assets/scss/navigation.scss'

const NavigationBar = () => {
    const location = useLocation();
    const activeColor = '#5bbf91';

    const isActive = (path) => {
        return location.pathname === path || (path === '/business-card' && location.pathname === '/business-detail');
    };

    return (
        <div className="container">
            <Link to="/home" className={`content ${isActive('/home') ? 'active' : ''}`}>
                <div className='icon'>
                    <HomeIcon fill={isActive('/home') ? activeColor : undefined} />
                </div>
                <span className="title" style={{ color: isActive('/home') ? activeColor : undefined }}>Trang chủ</span>
            </Link>
            <Link to="/business-card" className={`content ${isActive('/business-card') ? 'active' : ''}`}>
                <div className='icon'>
                    <BusinessCardIcon fill={isActive('/business-card') ? activeColor : undefined} />
                </div>
                <span className="title" style={{ color: isActive('/business-card') ? activeColor : undefined }}>Danh thiếp</span>
            </Link>
            <div className="content ">
                <div className='iconQR'>
                    <QRCodeIcon />

                </div>
                <div className="title">Quét mã QR</div>
            </div>
            <Link to="/contacts" className={`content ${isActive('/contacts') ? 'active' : ''}`}>
                <div className='icon'>
                    <ContactsIcon fill={isActive('/contacts') ? activeColor : undefined} />
                </div>
                <span className="title" style={{ color: isActive('/contacts') ? activeColor : undefined }}>Danh bạ</span>
            </Link>
            <Link to="/account" className={`content ${isActive('/account') ? 'active' : ''}`}>
                <div className='icon'>
                    <AccountIcon fill={isActive('/account') ? activeColor : undefined} />
                </div>
                <span className="title" style={{ color: isActive('/account') ? activeColor : undefined }}>Tài khoản</span>
            </Link>
        </div>
    );
};

export default NavigationBar;
