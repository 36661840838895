import React from 'react';
import { useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import StarIcon from '../assets/icon/StarIcon';
import CalendarIcon from '../assets/icon/CalendarIcon';
import EyeIcon from '../assets/icon/EyeIcon';
import ThreeDotIcon from '../assets/icon/ThreeDotIcon';
import EditIcon from '../assets/icon/EditIcon';
import ProductIcon from '../assets/icon/ProductIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import EnterpriseIcon from '../assets/icon/EnterpriseIcon';
import NewsIcon from '../assets/icon/NewsIcon';
import QRCodeIcon from '../assets/icon/QRCodeIcon';
import ContactsIcon from '../assets/icon/ContactIcon';
import QRCodeScanIcon from '../assets/icon/QRCodeScanIcon';
import ArrowRightIcon from '../assets/icon/ArrowRightIcon';
import ShareIcon from '../assets/icon/ShareIcon';

const Contact = () => {

    const [isImageClicked, setIsImageClicked] = useState(false);
    const [isImageClicked1, setIsImageClicked1] = useState(false);
    const [isImageClicked2, setIsImageClicked2] = useState(false);
    const [isImageClicked3, setIsImageClicked3] = useState(false);
    const [isImageClicked4, setIsImageClicked4] = useState(false);

    const handleImageClick = () => {
        setIsImageClicked(!isImageClicked);
    };
    const handleImageClick1 = () => {
        setIsImageClicked1(!isImageClicked1);
    };
    const handleImageClick2 = () => {
        setIsImageClicked2(!isImageClicked2);
    };
    const handleImageClick3 = () => {
        setIsImageClicked3(!isImageClicked3);
    };
    const handleImageClick4 = () => {
        setIsImageClicked4(!isImageClicked4);
    };

    return (
        <div className="p-4 mb-24 max-w-md">
            <div className=' flex overflow-x-auto'>
                <div className='min-w-26 flex flex-col justify-center items-center mr-5'>
                    <div className='w-14 h-14'>
                        <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjshxZ0WJagT91mJ9T13vfoz5MrP7PaRCEw&s' alt='' />
                    </div>
                    <div className='text-sm my-1 font-semibold'>Nestle</div>
                </div>
                <div className='min-w-26 flex flex-col justify-center items-center mr-5'>
                    <div className='w-14 h-14'>
                        <img className='w-14 h-14 border border-colorSecondary rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLADVFopInpqhApYqw7bbgN0yupoEGJEWmA&s' alt='' />
                    </div>
                    <div className='text-sm my-1 font-semibold'>Hồng Lam</div>
                </div>
                <div className='min-w-26 flex flex-col justify-center items-center mr-5'>
                    <div className='w-14 h-14'>
                        <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjbHrHvzYK5zYCVop6aCFK1emJ25xvfk5dzw&s' alt='' />
                    </div>
                    <div className='text-sm my-1 font-semibold'>Vinamilk</div>
                </div>
                <div className='min-w-26 flex flex-col justify-center items-center mr-5'>
                    <div className='w-14 h-14'>
                        <img className='w-14 h-14 border border-colorSecondary rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLADVFopInpqhApYqw7bbgN0yupoEGJEWmA&s' alt='' />
                    </div>
                    <div className='text-sm my-1 font-semibold'>Hồng Lam</div>
                </div>
                <div className='min-w-26 flex flex-col justify-center items-center mr-5'>
                    <div className='w-14 h-14'>
                        <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjbHrHvzYK5zYCVop6aCFK1emJ25xvfk5dzw&s' alt='' />
                    </div>
                    <div className='text-sm my-1 font-semibold'>Vinamilk</div>
                </div>
            </div>
            <div className=' h-14 mt-5 pl-2'>
                <Input size="large" addonBefore={<SearchOutlined />} placeholder="Tìm kiếm ..." />
            </div>
            <div className='mt-3 flex'>
                <button className='border border-colorPrimary rounded-lg bg-colorPrimary w-1/2 py-2 text-white font-semibold text-sm mr-2'>
                    GẦN ĐÂY
                </button>
                <button className='flex justify-center items-center border border-colorPrimary rounded-lg bg-white w-1/2 py-2 text-colorPrimary ml-2 font-semibold text-sm'>
                    QUAN TÂM
                    <div className='ml-2'>
                        <StarIcon width={"16px"} height={"16px"} stroke='#5bbf91' />
                    </div>
                </button>
            </div>
            <div>
                <div className='flex flex-row items-center justify-between mt-4'>
                    <div className='flex items-center'>
                        <div className='w-14 h-14 mr-3' onClick={handleImageClick}>
                            <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjshxZ0WJagT91mJ9T13vfoz5MrP7PaRCEw&s' alt='' />
                        </div>
                        <div className=''>
                            <div className='text-base font-semibold mb-2 '>Công ty cổ phẩn Nestle</div>
                            <div className='flex'>
                                <button className='mr-5 border border-colorPrimary rounded-lg bg-colorPrimary w-1/5 py-1 text-white font-semibold text-xs'>
                                    IT
                                </button>
                                <button className='mr-5 border border-colorSecondary rounded-lg bg-colorSecondary w-3/5 py-1 text-white font-semibold text-xs'>
                                    GIÁO DỤC
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='ml-16'>
                        <ThreeDotIcon width={"16px"} height={"16px"} />
                    </div>
                </div>
            </div>
            {isImageClicked && (
                <div className='mt-5 flex flex-row justify-evenly '>
                    <div className='flex flex-col justify-center items-center w-1/4'>
                        <div className=' w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <QRCodeScanIcon stroke='#5bbf91' fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>MÃ QR</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <StarIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>QUAN TÂM</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ContactsIcon fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>LIÊN HỆ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ShareIcon fill='#5bbf91' stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHIA SẺ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ArrowRightIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHI TIẾT</div>
                    </div>
                </div>
            )
            }
            <div className='flex flex-row justify-between items-center mt-4'>
                <div className='flex items-center'>
                    <div className='w-14 h-14 mr-3' onClick={handleImageClick1}>
                        <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjshxZ0WJagT91mJ9T13vfoz5MrP7PaRCEw&s' alt='' />
                    </div>
                    <div className=''>
                        <div className='text-base font-semibold mb-2 '>Công ty cổ phẩn Nestle</div>
                        <div className='flex'>
                            <button className='mr-5 border border-colorPrimary rounded-lg bg-colorPrimary w-1/5 py-1 text-white font-semibold text-xs'>
                                IT
                            </button>
                            <button className='mr-5 border border-colorSecondary rounded-lg bg-colorSecondary w-3/5 py-1 text-white font-semibold text-xs'>
                                GIÁO DỤC
                            </button>
                        </div>
                    </div>
                </div>

                <div className='ml-16'>
                    <ThreeDotIcon width={"16px"} height={"16px"} />
                </div>
            </div>
            {isImageClicked1 && (
                <div className='mt-5 flex flex-row justify-evenly '>
                    <div className='flex flex-col justify-center items-center w-1/4'>
                        <div className=' w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <QRCodeScanIcon stroke='#5bbf91' fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>MÃ QR</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <StarIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>QUAN TÂM</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ContactsIcon fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>LIÊN HỆ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ShareIcon fill='#5bbf91' stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHIA SẺ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ArrowRightIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHI TIẾT</div>
                    </div>
                </div>
            )
            }

            <div className='flex flex-row items-center justify-between mt-7'>
                <div className='flex items-center'>
                    <div className='w-14 h-14 mr-3' onClick={handleImageClick2}>
                        <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLADVFopInpqhApYqw7bbgN0yupoEGJEWmA&s' alt='' />
                    </div>
                    <div className=''>
                        <div className='text-base font-semibold mb-2 '>Công ty cổ phẩn Nestle</div>
                        <div className='flex'>
                            <button className='mr-5 border border-colorPrimary rounded-lg bg-colorPrimary w-1/5 py-1 text-white font-semibold text-xs'>
                                IT
                            </button>
                            <button className='mr-5 border border-colorSecondary rounded-lg bg-colorSecondary w-3/5 py-1 text-white font-semibold text-xs'>
                                GIÁO DỤC
                            </button>
                        </div>
                    </div>
                </div>

                <div className='ml-16'>
                    <ThreeDotIcon width={"16px"} height={"16px"} />
                </div>
            </div>
            {isImageClicked2 && (
                <div className='mt-5 flex flex-row justify-evenly '>
                    <div className='flex flex-col justify-center items-center w-1/4'>
                        <div className=' w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <QRCodeScanIcon stroke='#5bbf91' fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>MÃ QR</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <StarIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>QUAN TÂM</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ContactsIcon fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>LIÊN HỆ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ShareIcon fill='#5bbf91' stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHIA SẺ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ArrowRightIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHI TIẾT</div>
                    </div>
                </div>
            )
            }

            <div className='flex flex-row items-center justify-between mt-7'>
                <div className='flex items-center'>
                    <div className='w-14 h-14 mr-3' onClick={handleImageClick3}>
                        <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjbHrHvzYK5zYCVop6aCFK1emJ25xvfk5dzw&s' alt='' />
                    </div>
                    <div className=''>
                        <div className='text-base font-semibold mb-2 '>Công ty cổ phẩn Nestle</div>
                        <div className='flex'>
                            <button className='mr-5 border border-colorPrimary rounded-lg bg-colorPrimary w-1/5 py-1 text-white font-semibold text-xs'>
                                IT
                            </button>
                            <button className='mr-5 border border-colorSecondary rounded-lg bg-colorSecondary w-3/5 py-1 text-white font-semibold text-xs'>
                                GIÁO DỤC
                            </button>
                        </div>
                    </div>
                </div>

                <div className='ml-16'>
                    <ThreeDotIcon width={"16px"} height={"16px"} />
                </div>
            </div>

            {isImageClicked3 && (
                <div className='mt-5 flex flex-row justify-evenly '>
                    <div className='flex flex-col justify-center items-center w-1/4'>
                        <div className=' w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <QRCodeScanIcon stroke='#5bbf91' fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>MÃ QR</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <StarIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>QUAN TÂM</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ContactsIcon fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>LIÊN HỆ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ShareIcon fill='#5bbf91' stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHIA SẺ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ArrowRightIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHI TIẾT</div>
                    </div>
                </div>
            )
            }
            <div className='flex flex-row items-center justify-between mt-7'>
                <div className='flex items-center'>
                    <div className='w-14 h-14 mr-3' onClick={handleImageClick4}>
                        <img className='w-14 h-14 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjbHrHvzYK5zYCVop6aCFK1emJ25xvfk5dzw&s' alt='' />
                    </div>
                    <div className=''>
                        <div className='text-base font-semibold mb-2 '>Công ty cổ phẩn Nestle</div>
                        <div className='flex'>
                            <button className='mr-5 border border-colorPrimary rounded-lg bg-colorPrimary w-1/5 py-1 text-white font-semibold text-xs'>
                                IT
                            </button>
                            <button className='mr-5 border border-colorSecondary rounded-lg bg-colorSecondary w-3/5 py-1 text-white font-semibold text-xs'>
                                GIÁO DỤC
                            </button>
                        </div>
                    </div>
                </div>

                <div className='ml-16'>
                    <ThreeDotIcon width={"16px"} height={"16px"} />
                </div>
            </div>
            {isImageClicked4 && (
                <div className='mt-5 flex flex-row justify-evenly '>
                    <div className='flex flex-col justify-center items-center w-1/4'>
                        <div className=' w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <QRCodeScanIcon stroke='#5bbf91' fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>MÃ QR</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <StarIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>QUAN TÂM</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/3'>
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ContactsIcon fill='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>LIÊN HỆ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ShareIcon fill='#5bbf91' stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHIA SẺ</div>
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4' >
                        <div className='w-10 h-10 bg-white border-white p-2 border rounded-full flex justify-center items-center mb-2'>
                            <ArrowRightIcon stroke='#5bbf91' width={"24px"} height={"24px"} />
                        </div>
                        <div className='font-semibold text-xs'>CHI TIẾT</div>
                    </div>
                </div>
            )
            }

        </div>
    );
};

export default Contact;
