import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/headerhome.scss'
import SearchIcon from '../assets/icon/SearchIcon';
import NoticeIcon from '../assets/icon/NoticeIcon';
import EditIcon from '../assets/icon/EditIcon';
import ProductIcon from '../assets/icon/ProductIcon';
import ChartIcon from '../assets/icon/ChartIcon';
import EnterpriseIcon from '../assets/icon/EnterpriseIcon';
import NewsIcon from '../assets/icon/NewsIcon';
import LocationIcon from '../assets/icon/LocationIcon';
import ArrowDownIcon from '../assets/icon/ArrowDown';
import { Button } from 'antd';
import ArrowRightIcon from '../assets/icon/ArrowRightIcon';

const CompanyItem = () => {
    return (
        <div className='flex w-full overflow-x-auto'>
            <div className="relative min-w-80 shadow-xl border border-white rounded-xl pb-2 mr-4">
                <div className=''>
                    <img className='border border-white rounded-lg' src='https://suamilonestle.wordpress.com/wp-content/uploads/2017/12/sua-milo-dang-lon.jpg?w=1108&h=605&crop=1' alt=''/>
                </div>
                <div className='w-15 h-15 absolute top-32 left-10'>
                    <img className='w-20 h-20 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjshxZ0WJagT91mJ9T13vfoz5MrP7PaRCEw&s' alt=''/>
                </div>
                <div className='flex flex-row-reverse mt-5 pr-4 mb-5'>
                    <Button>Website Mini: 3</Button>
                </div>
                <div className='pl-3'>
                    <div className='text-base font-semibold mb-1 px-2'>Công ty cổ phẩn Nestle</div>
                    <div className='p-2 text-xs'>Là một doanh nghiệp đi đầu trong thiết kế website tại Việt Nam</div>
                    <div className='flex items-center p-2'>
                        <button className='border border-colorPrimary rounded-lg bg-colorPrimary px-2.5 py-2 text-white font-semibold text-sm'>
                            Lưu danh thiếp
                        </button>
                        <div className='ml-5'>
                            <ArrowRightIcon width={"20px"} height={"20px"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative min-w-80 shadow-xl rounded-xl pb-2 border border-white">
                <div className=''>
                    <img className='border border-white rounded-lg' src='https://img.websosanh.vn/v2/users/review/images/sua-vinamilk-organic-de-uong/d0eujmkixh8rg.jpg?compress=80' alt=''/>
                </div>
                <div className='w-15 h-15 absolute top-32 left-10'>
                    <img className='w-20 h-20 border rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjbHrHvzYK5zYCVop6aCFK1emJ25xvfk5dzw&s' alt=''/>
                </div>
                <div className='flex flex-row-reverse mt-5 pr-4 mb-5'>
                    <Button>Website Mini: 3</Button>
                </div>
                <div className='pl-3'>
                    <div className='text-base font-semibold mb-1 px-2'>Công ty cổ phẩn Vinamilk</div>
                    <div className='text-xs p-2'>Là một doanh nghiệp đi đầu trong thiết kế website tại Việt Nam</div>
                    <div className='flex items-center p-2'>
                        <button className='border border-colorPrimary rounded-lg bg-colorPrimary px-2.5 py-2 text-white font-semibold text-sm'>
                            Lưu danh thiếp
                        </button>
                        <div className='ml-5'>
                            <ArrowRightIcon width={"20px"} height={"20px"} stroke='#5bbf91' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyItem;
