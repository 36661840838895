import React from 'react';
import { Button, Input, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../assets/scss/global.scss'

const Register = () => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log('Register Successful:', values);
    notification.success({
      message: 'Đăng ký thành công',
      description: 'Bạn đã đăng ký tài khoản thành công!',
    });

    setTimeout(() => {
      navigate('/login');
    }, 1500);
  };

  return (
    <div className="p-8 max-w-md mx-auto">
      <div>
        <img src='https://upload.wikimedia.org/wikipedia/commons/8/83/Green_Party_of_Aotearoa_New_Zealand_logo.jpg' />
      </div>
      <h2 className="text-2xl mb-4 mt-8 font-serif text-center">Đăng ký</h2>
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item
          name="username"
          label="Tên đăng nhập"
          rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập của bạn!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Vui lòng nhập email của bạn!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu của bạn!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size={"large"}
            className={"w-full"}
            style={{ backgroundColor: '#5bbf91', borderColor: '#5bbf91' }}
          >
            Đăng ký
          </Button>
        </Form.Item>
      </Form>
      <div className="text-center mt-4">
        <span>Bạn đã có tài khoản? </span>
        <a href="/login" className="text-colorPrimary underline">Đăng nhập</a>
      </div>
    </div>
  );
};

export default Register;
