import React from 'react';

const TabBar = ({ activeTab, setActiveTab }) => {
    return (
        <div className="flex overflow-x-auto w-full bg-white shadow-lg px-4">
            <div
                className={`min-w-24 mr-1 text-center cursor-pointer text-sm font-semibold py-1 uppercase my-2 whitespace-nowrap ${activeTab === 'introduction' ? 'text-colorPrimary font-semibold bg-colorPrimary/20 rounded-lg ' : 'text-black/60'}`}
                onClick={() => setActiveTab('introduction')}
            >
                Giới thiệu
            </div>
            <div
                className={`min-w-24 mr-1 text-center cursor-pointer text-sm font-semibold py-1 uppercase my-2 whitespace-nowrap ${activeTab === 'details' ? 'text-colorPrimary font-semibold bg-colorPrimary/20 rounded-lg ' : 'text-black/60'}`}
                onClick={() => setActiveTab('details')}
            >
                Chi tiết
            </div>
            <div
                className={`min-w-24 mr-1 text-center cursor-pointer text-sm font-semibold py-1 uppercase my-2 whitespace-nowrap ${activeTab === 'articles' ? 'text-colorPrimary font-semibold bg-colorPrimary/20 rounded-lg ' : 'text-black/60'}`}
                onClick={() => setActiveTab('articles')}
            >
                Bài viết
            </div>
            <div
                className={`min-w-24 mr-1 text-center cursor-pointer text-sm font-semibold py-1 uppercase my-2 whitespace-nowrap ${activeTab === 'products' ? 'text-colorPrimary font-semibold bg-colorPrimary/20 rounded-lg ' : 'text-black/60'}`}
                onClick={() => setActiveTab('products')}
            >
                Sản phẩm
            </div>
            <div
                className={`min-w-24 mr-1 text-center cursor-pointer text-sm font-semibold py-1 uppercase my-2 whitespace-nowrap ${activeTab === 'legal' ? 'text-colorPrimary font-semibold bg-colorPrimary/20 rounded-lg ' : 'text-black/60'}`}
                onClick={() => setActiveTab('legal')}
            >
                Pháp lý
            </div>
        </div>
    );
};

export default TabBar;
