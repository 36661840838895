// src/components/Home.js
import React from 'react';
import { Button } from 'antd';
import HeaderHome from '../components/headerHome';
import HeaderContentHome from '../components/headerContentHome';
import Company from '../components/company';
import '../assets/scss/home.scss'
import Product from '../components/product';
import Post from '../components/post';
import Blog from '../components/blog';
import '../assets/scss/global.scss'
const Home = () => {
  return (
    <div className='container-home max-w-md'>
      <HeaderHome />
      <div className="p-4">
        <HeaderContentHome />
        {/* <h1 className="text-4xl mb-4">Welcome to the Home Page</h1>
        <Button type="primary">Go to Dashboard</Button> */}
        <Company />
        <Product/>
        <Post/>
        <Blog/>
      </div>
    </div>
  );
};
export default Home;
