// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: black;
  padding: 10px;
  border-top: 1px solid gainsboro;
  background-color: white;
}
.container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
.container .content .title {
  color: black;
  font-size: 12px;
  font-weight: 600;
}
.container .content .icon {
  margin-top: 4px;
}
.container .content .iconQR {
  margin-top: -35px;
  padding: 10px;
  background: #5bbf91;
  border-radius: 50%;
  border: 4px solid white;
  margin-bottom: 10px;
}
.container .content.active .icon,
.container .content.active .title {
  color: #5bbf91;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/navigation.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,+BAAA;EACA,uBAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;AACR;AACQ;EACI,YAAA;EACA,eAAA;EACA,gBAAA;AACZ;AAEQ;EACI,eAAA;AAAZ;AAGQ;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EAEA,mBAAA;AAFZ;AAOY;;EAEI,cAAA;AALhB","sourcesContent":[".container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    position: fixed;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n    color: black;\r\n    padding: 10px;\r\n    border-top: 1px solid gainsboro;\r\n    background-color: white;\r\n\r\n    .content {\r\n        display: flex;\r\n        flex-direction: column;\r\n        align-items: center;\r\n        text-decoration: none;\r\n        color: inherit;\r\n\r\n        .title {\r\n            color: black;\r\n            font-size: 12px;\r\n            font-weight: 600;\r\n        }\r\n\r\n        .icon {\r\n            margin-top: 4px;\r\n        }\r\n\r\n        .iconQR {\r\n            margin-top: -35px;\r\n            padding: 10px;\r\n            background: #5bbf91;\r\n            border-radius: 50%;\r\n            border: 4px solid white;\r\n            // border-bottom: 0;\r\n            margin-bottom: 10px;\r\n        }\r\n\r\n        &.active {\r\n\r\n            .icon,\r\n            .title {\r\n                color: #5bbf91;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
